<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <CIcon
        class="c-sidebar-brand-full"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 556 134"
      />
      <CIcon
        class="c-sidebar-brand-minimized"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 110 134"
      />
    </CSidebarBrand>

    <CSidebarNav>
      <CSidebarNavTitle>Доставки заказов</CSidebarNavTitle>
      <CSidebarNavItem
        name="Активные"
        icon="cil-car-alt"
        :badge="{color: 'warning', text: $store.state.countDeliveries}"
        :to="{path: '/delivery/list', query: {scope: 'active'}}"
      ></CSidebarNavItem>
      <CSidebarNavItem
        name="Выполненные"
        icon="cil-check-circle"
        :to="{path: '/delivery/list', query: {scope: 'done'}}"
      ></CSidebarNavItem>
      <CSidebarNavItem
        name="Все"
        icon="cil-align-left"
        :to="{path: '/delivery/list', query: {scope: 'all'}}"
      ></CSidebarNavItem>
    </CSidebarNav>

    <!--<CRenderFunction flat :content-to-render="$options.nav"/>-->

    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from './_nav'

export default {
  name: 'TheSidebar',
  nav,
  computed: {
    show() {
      return this.$store.state.sidebarShow
    },
    minimize() {
      return this.$store.state.sidebarMinimize
    }
  },
  async mounted() {
    let self = this;
    let response = await self.$http.get('/deliveries/active');
    self.$store.commit('set', ['countDeliveries', response.data.meta.total]);

    setInterval(async () => {
      let response = await self.$http.get('/deliveries/active');
      self.$store.commit('set', ['countDeliveries', response.data.meta.total]);
    }, 60 * 1000);
  }
}
</script>
