<template>
  <CFooter :fixed="false">
    <div>
      <a href="https://viola.ru" target="_blank">Виола</a>
      <span class="ml-1">&copy; {{new Date().getFullYear()}}</span>
    </div>
    <div class="mfs-auto">
      Powered by CoreUI
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
