<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img
            src="img/avatars/default_avatar.png"
            class="c-avatar-img "
          />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>{{accountName}}</strong>
    </CDropdownHeader>
    <CDropdownItem @click="accountLogout()">
      <CIcon name="cil-lock-locked"/>
      Выход
    </CDropdownItem>
    <!--<CDropdownItem>
      <CIcon name="cil-bell"/>
      Updates
      <CBadge color="info" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-envelope-open"/>
      Messages
      <CBadge color="success" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-task"/>
      Tasks
      <CBadge color="danger" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-comment-square"/>
      Comments
      <CBadge color="warning" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownHeader
      tag="div"
      class="text-center"
      color="light"
    >
      <strong>Settings</strong>
    </CDropdownHeader>
    <CDropdownItem>
      <CIcon name="cil-user"/>
      Profile
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-settings"/>
      Settings
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-dollar"/>
      Payments
      <CBadge color="secondary" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-file"/>
      Projects
      <CBadge color="primary" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownDivider/>
    <CDropdownItem>
      <CIcon name="cil-shield-alt"/>
      Lock Account
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-lock-locked"/>
      Logout
    </CDropdownItem>-->
  </CDropdown>
</template>

<script>
export default {
  name: 'TheHeaderDropdownAccnt',
  data() {
    return {
      itemsCount: 42,
      accountName: 'Аккаунт',
    }
  },
  mounted() {
    this.loadProfile();
  },
  methods: {
    async loadProfile() {
      let result = await this.$http.get('/users/current');
      this.accountName = result.data.data.name;
    },
    async accountLogout() {
      await localStorage.removeItem('token');
      this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
      await this.$router.push('/pages/login');
    }
  }
}
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>
